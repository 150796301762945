<script>
import {
    mapState,
} from 'vuex';

export default {
    name: 'DocsFileBrowser',

    props: {
        filePaths: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        const rootLevelPaths = new Set();
        this.filePaths.forEach((p) => rootLevelPaths.add(p.split('/')[0]));

        return {
            previewContentPrefixes: ['chat', 'insights', 'whatsapp'],
            rootLevelPaths,
        };
    },

    watch: {
        $route() {
            this.matchCurrentRouteToActiveDocument();
        },
    },

    mounted() {
        this.matchCurrentRouteToActiveDocument();
        this.mountTabNavigationHelper();
    },

    computed: {
        ...mapState([
            'isPreview',
        ]),

        tree() {
            const fileObjectTree = this.filePaths
                .reduce((objectTree, filePath) => {
                    let newTree = objectTree;
                    const subPaths = filePath.split('/');

                    for (let index = 0; index < subPaths.length; index += 1) {
                        const subPath = subPaths[index];
                        if (!newTree[subPath]) {
                            newTree[subPath] = {};
                        }
                        newTree = newTree[subPath];
                    }

                    newTree.path = filePath;
                    return objectTree;
                }, {});

            const result = {};
            Object.keys(fileObjectTree)
                .filter((key) => !this.previewContentPrefixes.includes(key) || this.isPreview)
                .forEach((key) => {
                    result[key] = fileObjectTree[key];
                });

            return result;
        },
    },

    methods: {
        closeAllFolders() {
            const openFolders = this.$refs.docsFileBrowser
                .querySelectorAll('.folder.open');

            if (openFolders) {
                Array.from(openFolders).forEach((folder) => {
                    folder.classList.remove('open');
                });
            }
        },

        handleFolderClick(evt) {
            evt.stopImmediatePropagation();
            evt.target.parentElement.classList.toggle('open');
        },

        mountTabNavigationHelper() {
            this.$refs.docsFileBrowser.addEventListener('focusin', (evt) => {
                const { parentElement: parent } = evt.target;
                const parentIsFolderContent = parent.classList.contains('folder__content');
                if (parentIsFolderContent) parent.parentElement.classList.add('open');
            }, true);
        },

        getParentFolders({ element, selector }) {
            const parents = [];
            // eslint-disable-next-line no-param-reassign
            for (; element && element !== this.$refs.docsFileBrowser; element = element.parentNode) {
                if (element && element.matches(selector)) {
                    parents.push(element);
                }
            }

            return parents;
        },

        highlightDocument(params = {}) {
            const { fileName = '' } = params;
            this.closeAllFolders();
            const files = this.$refs.docsFileBrowser.getElementsByTagName('a');
            const filesArray = Array.from(files);
            if (filesArray.length === 0) {
                return;
            }

            const activeFile = filesArray
                .find((file) => file.pathname === `/docs/${fileName}`);

            if (activeFile) {
                const fileParentFolders = this.getParentFolders({
                    element: activeFile,
                    selector: '.folder',
                });

                Array.from(fileParentFolders)
                    .forEach((folder) => {
                        folder.classList.add('open');
                    });
            }
        },

        matchCurrentRouteToActiveDocument() {
            if (/docs/.test(this.$route.path)) {
                this.highlightDocument({
                    fileName: this.$route.params.fileName,
                });
            } else {
                this.closeAllFolders();
            }
        },

        formatName(itemName) {
            const formattedName = itemName
                .split('.')[0]
                .split('_')
                .reduce((base, next) => {
                    let result;
                    if (next === 'sms' || next === 'ussd' || next === 'faq') {
                        result = next.toUpperCase();
                    } else if (next === 'iot') {
                        result = 'IoT';
                    } else if (next === 'data') {
                        result = 'Mobile Data';
                    } else {
                        result = next.charAt(0).toUpperCase() + next.slice(1);
                    }

                    const newValue = `${base} ${result}`;
                    return newValue;
                }, '');

            return formattedName.trim();
        },
    },

    render(createElement) {
        const createDocLink = ([treeKey, treeValue]) => {
            const linkName = this.formatName(treeKey);

            const createdLink = createElement(
                'router-link',
                {
                    class: 'px-2 -mx-2 py-1 transition duration-200 ease-in-out relative block hover:translate-x-2px hover:text-gray-900 text-gray-600',
                    props: {
                        'active-class': 'sidebar-link-active',
                    },
                    attrs: {
                        to: `/docs/${treeValue.path.replace('.md', '')}`,
                    },
                },
                [
                    createElement(
                        'span',
                        {
                            class: 'background rounded absolute inset-y-0 left-0 right-2',
                        },
                    ),
                    createElement(
                        'span',
                        {
                            class: 'text relative',
                        },
                        [linkName],
                    ),
                ],
            );

            return createdLink;
        };

        const createTree = ([treeKey, treeValue]) => {
            let tree;

            if (treeValue.path) {
                tree = createDocLink([treeKey, treeValue]);
            } else {
                const isTopLevelFolder = this.rootLevelPaths.has(treeKey);
                const folderName = this.formatName(treeKey);
                tree = createElement(
                    'div',
                    {
                        class: 'folder',
                    },
                    [
                        ...isTopLevelFolder ? [createElement(
                            'span',
                            {
                                class: 'folder__name flex flex-row items-center justify-between cursor-pointer px-2 -mx-2 py-1 transition duration-200 ease-in-out block hover:translate-x-2px hover:text-gray-900 text-gray-600',
                                on: { click: this.handleFolderClick },
                            },
                            [
                                folderName,
                                createElement(
                                    'caretRightSvg',
                                    {
                                        class: 'folder__icon--closed h-6 fill-current pointer-events-none',
                                    },
                                ),
                                createElement(
                                    'caretDownSvg',
                                    {
                                        class: 'folder__icon--open h-6 fill-current pointer-events-none',
                                    },
                                ),
                            ],
                        )] : [createElement(
                            'h5',
                            {
                                class: 'mt-3 mb-1 text-gray-500 uppercase tracking-wide font-bold text-sm lg:text-xs',
                            },
                            [folderName],
                        )],
                        createElement(
                            'div',
                            {
                                class: {
                                    'folder__content pl-4 border-l-2': isTopLevelFolder,
                                    'pl-3': !isTopLevelFolder,
                                },
                            },
                            [
                                Object.entries(treeValue)
                                    .map(([folderTreeKey, folderTreeValue]) => createTree([
                                        folderTreeKey,
                                        folderTreeValue,
                                    ])),
                            ],
                        ),
                    ],
                );
            }

            return tree;
        };

        return createElement(
            'div',
            {
                ref: 'docsFileBrowser',
                class: 'folder__root',
            },
            [
                Object.entries(this.tree)
                    .map(([treeKey, treeValue]) => createTree([
                        treeKey,
                        treeValue,
                    ])),
            ],
        );
    },

    components: {
        caretDownSvg: () => import('@/assets/img/caretDown.svg'),
        caretRightSvg: () => import('@/assets/img/caretRight.svg'),
    },
};
</script>

<style lang="postcss" scoped>
.folder {
    & > .folder__content {
        opacity: 0;
        height: 0;
        pointer-events: none;
    }

    &.open {
        & > .folder__content {
            opacity: 1;
            height: auto;
            pointer-events: auto;
        }

        & > .folder__name {
            color: theme('colors.orange.500');
        }

        & > .folder__name {
            & .folder__icon--open {
                display: inline-flex;
            }

            & .folder__icon--closed {
                display: none;
            }
        }

    }

    & .folder__name {
        & .folder__icon--open {
            display: none;
        }

        & .folder__icon--closed {
            display: block;
        }
    }
}
</style>
